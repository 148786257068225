@import '../../../styles/colors';

.steps {
  display: flex;
  justify-content: flex-start;

  .firstStep {
    &:nth-child(1) {
      background-color: $blue;
    }

    &:nth-child(2) {
      color: $darkblue;
      position: absolute;
      left: 200px;
      background-color: $white;
    }

    &:nth-child(3) {
      position: absolute;
      left: 400px;
      background-color: $white;
      color: $darkblue;
    }
  }
}

.header {
  color: $darkblue;
  font-family: ComoBold;
}

.form {
  width: 800px;
  display: flex;
  flex-direction: column;

  .inputs {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    .inputWrapper {
      margin-bottom: 15px;

      .input {
        width: 200px;
      }

      .errorInput {
        border: 2px solid red;
      }

      .label {
        color: $darkblue;
        font-family: ComoSemiBold;
      }

      .error {
        margin-top: 5px;
        color: red;
        font-family: ComoSemiBold;
      }
    }
  }

  .submit {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;

    .error {
      color: red;
      font-family: ComoSemiBold;
      margin-right: auto;
    }

    .disabledButton {
      color: red;
    }
  }
}
