@import '../../../styles/colors';

.steps {
  display: flex;
  justify-content: flex-start;

  .secondStep {
    &:nth-child(1) {
      background-color: $purple;
    }

    &:nth-child(2) {
      color: $white;
      position: absolute;
      left: 200px;
      background-color: $blue;
    }

    &:nth-child(3) {
      position: absolute;
      left: 400px;
      background-color: $white;
      color: $darkblue;
    }
  }
}

.checkboxesWrapper {
  display: grid;
  gap: 10px;
  align-self: baseline;
  .buttonAdd {
    width: 100% !important;
    margin-top: 10px;
  }
}

.itemHeader {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 63%;
  .delete {
    cursor: pointer;
  }
}

.form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .formContent {
    display: flex;

    .fields {
      .title {
        font-family: ComoBold;
        color: $darkblue;
        margin-bottom: 25px;
      }

      .signerBlock {
        display: flex;

        .signerInputs {
          display: flex;
          flex-wrap: wrap;
        }
      }

      .managerBlock {
        display: flex;
        width: 90%;
        flex-wrap: wrap;
      }

      .realPrincipalBlock {
        display: flex;

        .principalInputs {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-template-rows: max-content;
        }
      }

      .copyPrincipal {
        margin: 40px 0;
      }

      .sellerBlock {
        display: flex;
        width: 90%;
        flex-wrap: wrap;
      }

      .block {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 30px;
      }

      .inputWrapper {
        margin-bottom: 15px;

        .input {
          width: 230px;
          margin-top: 5px;
        }

        .errorInput {
          border: 2px solid red;
        }

        .label {
          color: $darkblue;
          font-family: ComoBold;
        }

        .error {
          margin-top: 5px;
          color: red;
          font-family: ComoSemiBold;
        }
      }
    }

    .checkboxes {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 1050px;
      padding-top: 90px;
    }
  }

  .button {
    margin-top: 20px;
    margin-left: auto;
  }
}
