@import '../../../styles/colors';

.container {
  width: 170px;
  text-align: center;

  .title {
    background-color: $lightgreen;
    height: 70px;
    border-radius: 20px 20px 0 0;
    box-shadow: 5px 1px 10px gray;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    font-family: ComoBold;
  }

  .body {
    background-color: $white;
    border-radius: 0 0 5px 5px;
    height: auto;
    box-shadow: 5px 1px 10px gray;

    .section {
      padding: 20px 0;
      color: $darkblue;

      p {
        font-family: ComoBold;
        font-size: 24px;
        margin-bottom: 5px;
        overflow: hidden;
        white-space: normal;
      }

      span {
        font-family: ComoSemiBold;
      }

      &:last-child {
        border-top: 2px solid $darkblue;
        width: 85%;
        margin: 0 auto;
      }
    }

    p {
      margin: 0;
    }
  }
}
