@import './../../../styles/colors';

.container {
  color: $white;
  width: 120px;
  margin-bottom: 15px;
  width: 120px;

  p {
    margin-bottom: 10px;
    font-family: ComoBold;
    font-size: 18px;
  }

  .link {
    margin-bottom: 5px;
    font-family: ComoBold;
    font-size: 18px;
    display: block;

    &:hover {
      color: $darkblue;
    }
  }

  span {
    font-size: 14px;
    font-family: ComoMedium;
  }
}
