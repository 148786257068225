@import '../../styles/colors';

.content {
  width: 90%;
  margin: 0 auto;
  margin-top: 50px;
  display: flex;
}

.agreement {
  text-align: center;
  margin: 0 auto;
}

.button {
  background-color: $purple;
  font-size: 12px;
  width: 180px;
  border-radius: 35px;
  padding: 15px 0;
  font-family: 'ComoBold';
  text-transform: none;
  color: $white;
  box-shadow: 5px 1px 10px gray;
  text-align: center;
  border: none;
  cursor: pointer;
  margin-top: 20px;

  button {
    background-color: inherit;
    font-family: 'ComoBold';
    border: none;
    cursor: pointer;
    color: $white;
    width: 180px;
    border-radius: 35px;
  }
}

.buttonSign {
  margin-top: 20px;
}
