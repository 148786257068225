@import '../../../styles/colors';

.cardContainer {
  background-color: $white;
  width: 200px;
  border-radius: 15px;
  text-align: center;
  padding: 40px 0;
  color: $darkblue;
  margin-bottom: 50px;
  box-shadow: 5px 1px 10px gray;

  .logo {
    display: flex;
    height: 30px;
    justify-content: center;
  }

  .cardHead {
    font-family: ComoBold;
    margin-bottom: 10px;
  }

  .cardItems {
    height: 110px;

    p {
      margin: 5px;
    }
  }

  .button {
    &:global(.MuiButton-root) {
      margin-right: 0 !important;
      width: 130px !important;
    }
  }
}
