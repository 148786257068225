.logoWrapper {
  display: flex;
  justify-content: flex-end;
  width: 95%;
  padding-top: 2em;

  .container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .logo {
      height: 85px;
    }

    .logout {
      width: 120px !important;
      margin-right: 5px !important;
      z-index: 999;
    }
  }
}
