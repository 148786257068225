@import '../../../../styles/colors';

.sideLinks {
  display: flex;
  flex-direction: column;
  width: 350px;
  align-items: flex-end;
  border-right: 1px solid $white;
  height: 500px;

  .link {
    color: $white;
    font-family: ComoSemiBold;
    font-size: 18px;

    &:hover {
      color: $darkblue;
    }
  }

  .active {
    color: $darkblue;
  }
}
