@import '../../styles/colors';

.searchInput {
  width: 90%;
  margin: 0 auto;
  position: relative;
  top: -70px;

  .roundedInput {
    border-radius: 20px;
    margin-top: 10px;
  }
}

.mainContent {
  width: 100%;
  min-width: 700px;
  padding: 0 20px;
  margin: 0 auto;
  padding-bottom: 50px;

  @media screen and (min-width: 900px) {
    width: 50%;
  }

  .headName {
    width: 40%;
    line-height: 1.5em;
    color: $darkblue;
    font-family: ComoSemiBold;
    margin-bottom: 30px;
  }

  .docsHeading {
    font-family: ComoSemiBold;
    color: $darkblue;
    height: 40px;
  }

  .cardContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    // justify-content: space-between;
  }
}
