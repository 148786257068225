@font-face {
  font-family: 'Como';
  src: url('./fonts/Como.woff2') format('woff2');
  font-style: normal;
}

@font-face {
  font-family: 'ComoBold';
  src: url('./fonts/Como-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'ComoExtraBold';
  src: url('./fonts/Como-ExtraBold.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'ComoExtraLight';
  src: url('./fonts/Como-ExtraLight.woff2') format('woff2');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'ComoHeavy';
  src: url('./fonts/Como-Heavy.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'ComoLight';
  src: url('./fonts/Como-Light.woff2') format('woff2');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'ComoMedium';
  src: url('./fonts/Como-Medium.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'ComoSemiBold';
  src: url('./fonts/Como-SemiBold.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}
