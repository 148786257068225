@import '../../../styles/colors';

.container {
  width: 100%;
  background-color: $lightblue;
  margin-bottom: 30px;

  .header {
    color: $darkblue;
    font-family: ComoBold;
    font-size: 20px;
    text-align: center;

    th {
      width: 15%;

      &:nth-child(1) {
        width: 30%;
        text-align: left;
      }
    }
  }

  .tableRow {
    height: 30px;
    color: $white;
    font-family: ComoBold;
    text-align: center;
    font-size: 14px;
  }

  p {
    width: 80px;
    overflow: hidden;
    //white-space: wr;
  }
}

.sectionTitle {
  text-align: left;
  font-family: ComoBold;
  font-size: 16px;
}

.rowTitle {
  text-align: left;
  color: $white;
}

.input {
  width: 30px;
  outline: none;
  background-color: inherit;
  border: none;
  color: $white;
  font-family: ComoBold;
  text-align: center;
  font-size: 14px;
  border-bottom: 1px solid $white;
}

.pingInput {
  width: 50px;
  outline: none;
  background-color: inherit;
  border: none;
  color: $white;
  font-family: ComoBold;
  text-align: center;
  font-size: 14px;
  border-bottom: 1px solid $white;

  &:disabled {
    border-bottom: none;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
