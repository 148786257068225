@import '../../../styles/colors';

.forgotPasswordForm {
  color: $white;

  .formHeader {
    width: 150px;
    margin: 0;
    font-family: ComoSemiBold;
  }

  .formInfo {
    margin-top: 0;
    width: 250px;
    font-family: ComoMedium;
    line-height: 1.5em;
  }

  input {
    margin-bottom: 20px;
  }

  .success {
    color: green;
  }

  .button {
    &:global(.MuiButton-root) {
      width: 100px !important;
    }
  }
}
