p {
  margin: 0 0 10px;
}

select {
  min-width: 50px;
  height: 30px;
  outline: none;
  cursor: pointer;
}
