@import 'src/styles/colors';

.container {
  background: $lightTheme;
  min-height: 100vh;
  padding-bottom: 60px;

  .logo {
    height: 200px;
  }

  .content {
    .contentWrapper {
      display: flex;
      width: 90%;
      margin: 0 auto;

      .buttons {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .disabled {
          pointer-events: none;
        }

        .activeButtons {
          display: flex;
          flex-direction: column;
          width: 370px;
          margin-top: 70px;

          .button {
            margin: 5px 0;
          }

          .dublicate {
            background-color: grey;
          }

          .download {
            background-color: $purple;
            width: 180px;
            border-radius: 35px;
            padding: 15px 24px;
            font-size: 12px;
            font-family: 'ComoBold';
            text-transform: none;
            color: $white;
            box-shadow: 5px 1px 10px gray;
            text-align: center;
            border: none;
            cursor: pointer;
          }
        }
      }

      .checkboxes {
        display: flex;
        flex-direction: column;
        margin-top: 50px;
      }

      .agreements {
        text-align: center;
        margin: 0 auto;
      }
    }
  }
}
