@import '../../../styles/colors';

.container {
  display: flex;
  position: relative;
  width: 700px;
  height: 170px;

  .step {
    color: $white;
    width: 350px;
    height: 100px;
    border-radius: 50px;
    padding-left: 30px;
    font-family: ComoBold;
    align-items: center;
    text-align: center;
    display: flex;
    border: 2px solid $blue;
    box-shadow: 5px 1px 10px gray;

    &:nth-child(1) {
      background-color: $blue;
    }

    &:nth-child(2) {
      color: red;
      width: 400px;
      color: $darkblue;
      position: absolute;
      left: 200px;
      text-align: center;
      display: flex;
      align-items: center;
    }

    &:nth-child(3) {
      position: absolute;
      left: 400px;
    }

    button {
      background-color: inherit;
      cursor: pointer;
      border: none;
      width: 90%;
      text-align: left;
      height: 90%;
      color: $darkblue;
      font-family: ComoBold;
      font-size: 16px;
    }
  }
}
