$lightgreen: #32ada9;
$lightblue: #76a9db;
$darkblue: #10225c;
$blue: #2072f5;
$purple: #997fb9;
$yellow: #f5ad20;
$white: #fff;

$lightTheme: linear-gradient(
  90deg,
  rgba(210, 211, 234, 1) 16%,
  rgba(205, 223, 242, 1) 59%
);

$darkTheme: linear-gradient(
  90deg,
  rgba(128, 131, 195, 1) 16%,
  rgba(118, 169, 219, 1) 59%
);
