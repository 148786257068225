@import '../../../styles/colors';

.inputWrapper {
  display: flex;
  flex-direction: column;
  width: 250px;

  .label {
    margin-bottom: 5px;
    color: white;
    font-family: ComoMedium;
  }

  .input {
    height: 40px;
    padding: 8px 16px;
    line-height: 25px;
    font-size: 18px;
    font-weight: 500;
    font-family: ComoBold;
    border-radius: 6px;
    color: $darkblue;
    box-shadow: 5px 1px 10px gray;
    border: 1px solid var(--input-border);
    transition: border 0.3s ease;

    &:focus {
      outline: none;
    }
  }
}
