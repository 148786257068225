@import '../../../styles/colors';

.container {
  background: $lightTheme;
  min-height: 100vh;
  padding-bottom: 60px;

  .content {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 800px;
  }
}
