@import '../../../styles/colors';

.loginForm {
  width: min-content;

  .form {
    margin-bottom: 20px;

    input {
      margin-bottom: 20px;
    }

    .button {
      &:global(.MuiButton-root) {
        width: 100px !important;
      }
    }
  }

  .link {
    font-family: ComoMedium;
    color: $white;

    &:hover {
      color: $darkblue;
    }
  }
}
