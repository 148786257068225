.container {
  width: 50%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .contentWrapper {
    width: 60%;

    .roundedInput {
      border-radius: 20px;
      margin-top: 10px;
    }

    .list {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 10px;
      column-gap: 20px;
      margin-top: 20px;
    }
  }

  .pagination {
    width: 60%;
    margin-left: 3em;
  }
}
