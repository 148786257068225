@import '../../styles/colors';

.container {
  background: $lightTheme;
  min-height: 100vh;
  padding-bottom: 60px;

  .contentWrapper {
    width: 90%;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;

    .leftContent {
      width: 48%;

      h3 {
        margin: 0;
      }

      .foretaget {
        color: $darkblue;
        font-family: ComoBold;
        height: 170px;
        padding-bottom: 30px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      }

      .label {
        color: #000;
      }

      .steps {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        .thirdStep {
          width: 300px;

          &:nth-child(1) {
            background-color: $purple;
          }

          &:nth-child(2) {
            color: $white;
            position: absolute;
            left: 140px;
            background-color: $purple;
          }

          &:nth-child(3) {
            position: absolute;
            left: 290px;
            background-color: $blue;
            color: $white;
          }
        }
      }

      .logo {
        justify-content: start;
        margin-bottom: 50px;
      }

      .infoSection {
        display: flex;
        justify-content: space-between;
        padding: 20px 0;

        .avtalspriser {
          color: $darkblue;
          font-family: ComoBold;
        }

        .dateSelections {
          display: flex;
          width: 330px;
          justify-content: space-between;
          font-family: ComoSemiBold;
          font-size: 16px;
          margin-bottom: 20px;
          gap: 10px;
        }
      }
    }

    .rightContent {
      width: 48%;

      .scalesCards {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 10px;
        height: 350px;
        align-items: center;
      }

      .totalCards {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 10px;
        // justify-content: space-between;
        height: 370px;
        margin-bottom: 30px;
        align-items: center;
      }

      .button {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}
